var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"align-self-center text-white rounded-circle bg-dark-green font-rem-1-0 mx-3 d-flex justify-content-center align-items-center",style:({
        'min-width': '30px',
        width: '30px',
        'min-height': '30px',
        height: '30px'
      })},[_c('span',{},[_vm._v(" 1")])]),_c('div',{staticClass:"brown-green-bold text-left font-rem-0-9 pt-5"},[_vm._v(" Asegúrate que la caja está conectada a la energía y tiene los bombillos prendidos. ")])]),_c('img',{staticClass:"my-2 align-self-center",attrs:{"src":"/1.jpg","height":"167px","width":"167px","alt":"mid-box"}}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"align-self-center text-white rounded-circle align-self-center bg-dark-green font-rem-1-0 mx-3 d-flex justify-content-center align-items-center",style:({
        'min-width': '30px',
        width: '30px',
        'min-height': '30px',
        height: '30px'
      })},[_c('span',{},[_vm._v(" 2")])]),_c('div',{staticClass:"brown-green-bold text-left font-rem-0-9 pt-5"},[_vm._v(" Activa la red wifi del celular. ")])]),_c('img',{staticClass:"my-2 align-self-center",attrs:{"src":"/2.jpg","height":"77px","width":"267px","alt":"mid-box"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }