var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-absolute d-flex flex-column"},[_c('head-bar'),_c('div',{class:`${
      _vm.$route.query.single || _vm.$route.query.listing ? '' : 'head-bar-visible'
    } bg-gray-light w-100 px-1`,style:({
      'overflow-x': 'hidden',
      'min-height': '100vh',
      width: '100vw !important'
    })},[_c('div',{staticClass:"h-100 py-5 px-2 d-flex flex-column justify-content-around align-items-center"},[_c('div',{staticClass:"container d-flex flex-column px-3 justify-content-around",style:({
          height: '100vh !important'
        })},[_vm._m(0),_c('div',{staticClass:"py-2 px-3 mt-3 w-75 rounded bg-dark-green shadow-sm pointer d-flex flex-column justify-content-center text-white w-fit-content align-self-center"},[_c('div',{staticClass:"align-self-center font-rem-2"},[_vm._v(" Proximamente mas info en : ")]),_c('br'),_c('div',{staticClass:"align-self-center font-rem-2"},[_vm._v(_vm._s(_vm.countString))])]),_c('div',{staticClass:"px-4 pt-4 pb-4 my-5 mx-5 h-max-content brown-white-bold bg-black-light rounded w-75 align-self-center"},[_c('p',[_vm._v("Usuario registrado?")]),_c('div',{staticClass:"d-flex align-items-center justify-content-around"},[_c('app-store'),_c('google-play'),_c('web-app'),_c('contactanos')],1)])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2 mb-4"},[_c('img',{attrs:{"src":"/V-logo-verdeoscuro.png","width":"100%","aria-label":"wSeeds logo"}})])])
}]

export { render, staticRenderFns }