var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(
      _vm.$route.query &&
      !_vm.$route.query.mode &&
      !_vm.$route.query.single &&
      !_vm.$route.query.serial &&
      !_vm.$route.query.listing
    )?_c('div',[_c('notifications-options')],1):(_vm.$route.query.mode == 'view')?_c('div',{staticClass:"d-flex my-4 flex-column"},[_c('div',{staticClass:"align-self-start",on:{"click":function($event){return _vm.$router.push({ query: {} })}}},[_c('inline-svg',{staticClass:"align-self-center t-rotate-180",attrs:{"src":"/flecha-ladoder.svg","width":"45px","height":"45px","fill":"white","aria-label":"Loading..."}})],1),_vm._l((_vm.objects),function(box){return _c('box-card',{key:box._id,attrs:{"box":box}})})],2):(_vm.$route.query && _vm.$route.query.single && _vm.$route.query.serial)?_c('div',[_c('notifications-editor')],1):(_vm.$route.query && _vm.$route.query.listing)?_c('div',[_c('notifications-listing')],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }