var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column w-100 mb-3"},[_c('div',{staticClass:"px-3 d-flex justify-content-between w-100"},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"brown-green-bold text-left font-rem-1-2 p",style:({ 'padding-right': '2px' })},[_vm._v(_vm._s(_vm.titleCard))]),(_vm.geoRef)?_c('inline-svg',{staticClass:"align-self-center mr-2 ml-0",attrs:{"src":"/localizacion.svg","width":"19px","height":"19px","aria-label":"Loading..."}}):_vm._e()],1),_c('div',{staticClass:"align-self-center",on:{"click":function($event){return _vm.$router.push({
          query: { single: undefined, mode: 'edit', serial: _vm.box.serial }
        })}}},[_c('inline-svg',{staticClass:"align-self-center",attrs:{"src":"/configuracion.svg","width":"30px","height":"30px","fill":"white","aria-label":"Loading..."}})],1)]),_c('div',{staticClass:"pr-0 mt-3 mb-2 mx-4 h-max-content rounded bg-white shadow-sm pointer d-flex brown-green-bold font-rem-1 w-auto justify-content-between"},[_c('div',{staticClass:"d-flex flex-column font-rem-0-85"},[_c('div',{staticClass:"px-2 py-1 d-flex justify-content-between"},[_c('div',{staticStyle:{"text-align":"left"}},[_c('span',{staticClass:"align-self-start"},[_vm._v(" Tipo: "+_vm._s(_vm.box.description)+" ")])]),_c('div',{class:`align-self-center rounded-circle align-self-center h-auto ${
            !_vm.boxState(_vm.box.serial) ? 'bg-red' : 'bg-green'
          }`},[_c('div',{staticClass:"rounded-circle invisible",style:({ width: '20px', heigth: '20px' })},[_vm._v(" c ")])])]),_c('div',{staticClass:"d-flex justify-content-evenly"},_vm._l((['temperature', 'light', 'humidity']),function(key){return _c('div',{key:key},[_c('widget-value',{attrs:{"full":_vm.getValue(key)}})],1)}),0)]),_c('router-link',{staticClass:"bg-orange w-25 d-flex flex-column justify-content-center rounded",attrs:{"to":{
        query: { single: true, mode: undefined, serial: _vm.box.serial }
      }}},[_c('inline-svg',{staticClass:"align-self-center",attrs:{"src":"/flecha-grande.svg","width":"22px","height":"47px","fill":"white","aria-label":"Loading..."}})],1)],1),(_vm.box.serial.includes('m_'))?_c('div',{staticClass:"py-2 mt-0 mb-3 mx-4 bg-white font-rem-0-8 d-flex rounded brown-white-bold-thin justify-content-around"},[_c('inline-svg',{staticClass:"align-self-center mr-2 ml-0",attrs:{"src":"/fan.svg","width":"19px","color":_vm.box.AIR_status ? 'green' : 'red',"height":"19px","aria-label":"Loading..."}}),_c('inline-svg',{staticClass:"align-self-center mr-2 ml-0",attrs:{"src":_vm.box.light_status ? '/lightbulb.svg' : '/lightbulb-off.svg',"width":"19px","height":"19px","color":"black","aria-label":"Loading..."}})],1):_vm._e(),(!_vm.boxState(_vm.box.serial))?_c('div',{staticClass:"py-2 mt-0 mb-3 mx-4 bg-red font-rem-0-8 rounded brown-white-bold-thin"},[_vm._v(" Revisa la conexión de tu MidBox ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }