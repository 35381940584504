var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-absolute d-flex flex-column",style:({
    'overflow-x': 'hidden',
    'min-height': '100vh',
    width: '100vw !important'
  })},[_vm._v(" Hello admin "),(_vm.$route.params.viewA)?_c('admin-view'):_c('div',[_c('button',{staticClass:"my-3 mx-3 h-max-content h-3-5-r rounded bg-white shadow-sm pointer d-flex justify-content-between text-grey-medium w-auto",on:{"click":function($event){return _vm.$router.push({
          name: 'Admin',
          params: { viewA: 'map' },
          query: {}
        })}}},[_c('span',{staticClass:"mx-3 align-self-center"},[_vm._v("Geo Boxes")]),_c('div',{staticClass:"align-self-center"})]),_c('button',{staticClass:"my-3 mx-3 h-max-content h-3-5-r rounded bg-white shadow-sm pointer d-flex justify-content-between text-grey-medium w-auto",on:{"click":function($event){return _vm.$router.push({
          name: 'Admin',
          params: { viewA: 'editor' },
          query: {}
        })}}},[_c('span',{staticClass:"mx-3 align-self-center"},[_vm._v("Report Editor ")]),_c('div',{staticClass:"align-self-center"})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }