var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column-reverse mt-4"},[_c('button',{class:{
      'align-self-center py-2 px-3 mt-5 w-75 h-50 rounded  shadow-sm pointer d-flex justify-content-center text-white w-fit-content': true,
      'bg-dark-green': _vm.validatedBox,
      'bg-gray': !_vm.validatedBox
    },attrs:{"disabled":!_vm.validatedBox},on:{"click":function($event){return _vm.navigateBoxes()}}},[_vm._v(" Activa tu midboxX ")]),_c('div',{staticClass:"d-flex flex-row justify-content-center mb-5"},[_c('div',{class:{
        'd-flex flex-column justify-content-center font-rem-0-7 text mx-4 ': true,
        'brown-wGreyMedium-bold': !_vm.boxMeasu,
        'brown-green-regular': _vm.boxMeasu
      }},[_c('span',[_vm._v(_vm._s(_vm.boxMeasu ? "Los datos de tu MidBox están subiendo al servidor" : "Los datos de tu MidBox no están subiendo al servidor"))])]),_c('div',{class:{
        'd-flex  rounded-circle justify-content-center mx-4': true,
        'bg-gray ': !_vm.boxMeasu,
        'bg-green ': _vm.boxMeasu
      },style:({
        'min-width': '60px',
        width: '60px',
        'min-height': '60px',
        height: '60px'
      }),on:{"click":function($event){return _vm.validateMeas()}}},[_c('inline-svg',{class:{
          'align-self-center justify-self-end ': true
        },attrs:{"src":"/icloud.svg","width":"30px","height":"30px","fill":"black","aria-label":"Loading..."}})],1)]),_c('div',{staticClass:"d-flex flex-row justify-content-center mb-5"},[_c('div',{class:{
        'd-flex flex-column justify-content-center font-rem-0-7 text mx-4 ': true,
        'brown-wGreyMedium-bold': !_vm.boxLinked,
        'brown-green-regular': _vm.boxLinked
      }},[_c('span',[_vm._v(_vm._s(_vm.boxLinked ? "Tu MidBox esta vinculada con el celular y aplicación" : "Tu MidBox no esta vinculada con el celular y aplicación"))])]),_c('div',{class:{
        'd-flex  rounded-circle justify-content-center mx-4': true,
        'bg-gray ': !_vm.boxLinked,
        'bg-green ': _vm.boxLinked
      },style:({
        'min-width': '60px',
        width: '60px',
        'min-height': '60px',
        height: '60px'
      }),on:{"click":_vm.validateLink}},[_c('inline-svg',{class:{
          'align-self-center justify-self-end ': true
        },attrs:{"src":"/red-celular.svg","width":"30px","height":"30px","fill":"black","aria-label":"Loading..."}})],1)]),_c('div',{staticClass:"d-flex flex-row justify-content-center mb-5"},[_c('div',{class:{
        'd-flex flex-column justify-content-center font-rem-0-7 text mx-4 ': true,
        'brown-wGreyMedium-bold': !_vm.boxOn,
        'brown-green-regular': _vm.boxOn
      }},[_c('span',[_vm._v(_vm._s(_vm.boxOn ? "Tu MidBox esta encendida" : "Tu MidBox no esta encendida"))])]),_c('div',{class:{
        'd-flex  rounded-circle justify-content-center mx-4': true,
        'bg-gray ': !_vm.boxOn,
        'bg-green ': _vm.boxOn
      },style:({
        'min-width': '60px',
        width: '60px',
        'min-height': '60px',
        height: '60px'
      }),on:{"click":_vm.validateOn}},[_c('inline-svg',{class:{
          'align-self-center justify-self-end ': true
        },attrs:{"src":_vm.boxOn ? '/on.svg' : '/off.svg',"width":"30px","height":"30px","fill":"black","aria-label":"Loading..."}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }