var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"align-self-start d-flex"},[_c('inline-svg',{staticClass:"align-self-start t-rotate-180",attrs:{"src":"/flecha-ladoder.svg","width":"45px","height":"45px","fill":"white","aria-label":"Loading..."},on:{"click":function($event){return _vm.$router.push(-1)}}})],1),_c('div',{staticClass:"align-self-center col-10 col-md-6 col-xl-4 bg-white rounded my-3 p-4 p-md-5 shadow d-flex flex-column align-items-center"},[_c('label',{class:{
        'mb-3 w-100': true,
        error: _vm.$v.name.$error,
        green: _vm.$v.name.$dirty && !_vm.$v.name.$error
      }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"w-100 brown-wGreyMedium font-rem-0-8 rounded border",attrs:{"placeholder":"Lugar / Ubicación"},domProps:{"value":(_vm.name)},on:{"change":function($event){return _vm.$v.name.$touch()},"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})]),_c('transition-expanded',[(_vm.$v.name.$dirty && _vm.$v.name.$error)?_c('div',{staticClass:"align-self-start"},[_c('p',{staticClass:"font-rem-0-75 error"},[_vm._v(" Debe ser un nombre de almenos 4 caracteres ")])]):_vm._e()]),_c('label',{class:{
        'mb-3 w-100': true
      }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.instalation_date),expression:"instalation_date"}],staticClass:"w-100 brown-wGreyMedium font-rem-0-8 rounded border",attrs:{"type":"date","placeholder":"Fecha de instalación"},domProps:{"value":(_vm.instalation_date)},on:{"input":function($event){if($event.target.composing)return;_vm.instalation_date=$event.target.value}}})]),_c('label',{class:{
        'mb-3 w-100': true,
        error: _vm.$v.serial.$error,
        green: _vm.$v.serial.$dirty && !_vm.$v.serial.$error
      }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.serial),expression:"serial"}],staticClass:"w-100 brown-wGreyMedium font-rem-0-8 rounded border",attrs:{"disabled":_vm.$route.query.newBox ? false : true,"placeholder":"ID MidBox"},domProps:{"value":(_vm.serial)},on:{"change":function($event){return _vm.$v.serial.$touch()},"input":function($event){if($event.target.composing)return;_vm.serial=$event.target.value}}})]),_c('transition-expanded',[(_vm.$v.serial.$dirty && _vm.$v.serial.$error)?_c('div',{staticClass:"align-self-start"},[_c('p',{staticClass:"font-rem-0-75 error"},[_vm._v(" Debe ser un serial valido, de tipo `w_#` ")])]):_vm._e()]),_c('label',{class:{
        'mb-3 w-100': true,
        error: _vm.$v.description.$error,
        green: _vm.$v.description.$dirty && !_vm.$v.description.$error
      }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],staticClass:"w-100 brown-wGreyMedium font-rem-0-8 rounded border",attrs:{"placeholder":"Tipo de Cultivo"},domProps:{"value":(_vm.description)},on:{"change":function($event){return _vm.$v.description.$touch()},"input":[function($event){if($event.target.composing)return;_vm.description=$event.target.value},function($event){return _vm.$v.$touch()}]}})]),_c('transition-expanded',[(_vm.$v.description.$dirty && _vm.$v.description.$error)?_c('div',{staticClass:"align-self-start"},[_c('p',{staticClass:"font-rem-0-75 error"},[_vm._v("debe tener almenos 4 caracteres")])]):_vm._e()]),(_vm.isMType)?_c('label',{class:{
        'mb-3 w-100': true
      }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.temperatureDesired),expression:"temperatureDesired"}],staticClass:"w-100 brown-wGreyMedium font-rem-0-8 rounded border",attrs:{"placeholder":"desired temperature"},domProps:{"value":(_vm.temperatureDesired)},on:{"input":[function($event){if($event.target.composing)return;_vm.temperatureDesired=$event.target.value},function($event){return _vm.$v.$touch()}]}})]):_vm._e(),(_vm.isMType || _vm.isAType)?_c('label',{class:{
        'mb-3 w-100': true
      }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lightOn),expression:"lightOn"}],staticClass:"w-100 brown-wGreyMedium font-rem-0-8 rounded border",attrs:{"type":"time","placeholder":"Light On"},domProps:{"value":(_vm.lightOn)},on:{"input":[function($event){if($event.target.composing)return;_vm.lightOn=$event.target.value},function($event){return _vm.$v.$touch()}]}})]):_vm._e(),(_vm.isMType || _vm.isAType)?_c('label',{class:{
        'mb-3 w-100': true
      }},[_c('i',{staticClass:"glyphicon glyphicon-cloud"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lightOff),expression:"lightOff"}],staticClass:"w-100 brown-wGreyMedium font-rem-0-8 rounded border",attrs:{"type":"time","placeholder":"Lights Off"},domProps:{"value":(_vm.lightOff)},on:{"input":[function($event){if($event.target.composing)return;_vm.lightOff=$event.target.value},function($event){return _vm.$v.$touch()}]}})]):_vm._e(),(_vm.isAType)?_c('label',{class:{
        'mb-3 w-100': true,
        error: _vm.$v.t_on.$error,
        green: _vm.$v.t_on.$dirty && !_vm.$v.t_on.$error
      }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.t_on),expression:"t_on"}],staticClass:"w-100 brown-wGreyMedium font-rem-0-8 rounded border",attrs:{"type":"number","placeholder":"Tiempo de encendido"},domProps:{"value":(_vm.t_on)},on:{"change":function($event){return _vm.$v.t_on.$touch()},"input":[function($event){if($event.target.composing)return;_vm.t_on=$event.target.value},function($event){return _vm.$v.$touch()}]}})]):_vm._e(),_c('transition-expanded',[(_vm.$v.t_on.$dirty && _vm.$v.t_on.$error)?_c('div',{staticClass:"align-self-start"},[_c('p',{staticClass:"font-rem-0-75 error"},[_vm._v("invalid t_on")])]):_vm._e()]),(_vm.isAType)?_c('label',{class:{
        'mb-3 w-100': true,
        error: _vm.$v.t_off.$error,
        green: _vm.$v.t_off.$dirty && !_vm.$v.t_off.$error
      }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.t_off),expression:"t_off"}],staticClass:"w-100 brown-wGreyMedium font-rem-0-8 rounded border",attrs:{"type":"number","placeholder":"Tiempo de apagado"},domProps:{"value":(_vm.t_off)},on:{"change":function($event){return _vm.$v.t_off.$touch()},"input":[function($event){if($event.target.composing)return;_vm.t_off=$event.target.value},function($event){return _vm.$v.$touch()}]}})]):_vm._e(),_c('transition-expanded',[(_vm.$v.t_off.$dirty && _vm.$v.t_off.$error)?_c('div',{staticClass:"align-self-start"},[_c('p',{staticClass:"font-rem-0-75 error"},[_vm._v("invalid t_off")])]):_vm._e()]),_c('label',{class:{
        'mb-3 w-100': true
      }},[_c('span',{staticClass:"d-block mb-2"},[_vm._v("Localización caja:")]),_c('button',{on:{"click":function($event){_vm.showMap = !_vm.showMap}}},[_c('inline-svg',{staticClass:"flex-grow-1",attrs:{"src":"/localizacion.svg","fill":"black","width":"30px","height":"30px","aria-label":"Loading..."}})],1)]),_c('button',{staticClass:"py-2 px-3 mt-3 w-75 h-50 rounded bg-dark-green shadow-sm pointer d-flex justify-content-center text-white w-fit-content",attrs:{"disabled":_vm.$v.$invalid},on:{"click":function($event){return _vm.saveClick()}}},[(_vm.$route.query.newBox)?_c('span',{staticClass:"align-self-center"},[_vm._v(" registrar caja")]):_c('span',{staticClass:"align-self-center"},[_vm._v(" Guardar cambios")])]),(!_vm.$route.query.newBox)?_c('button',{staticClass:"py-2 px-3 mt-3 w-75 h-50 rounded bg-red shadow-sm pointer d-flex justify-content-center text-white w-fit-content",on:{"click":function($event){return _vm.deleteClick()}}},[_c('span',{staticClass:"align-self-center"},[_vm._v(" Eliminar Caja")])]):_vm._e()],1),_c('transition',{attrs:{"name":"slide"}},[(_vm.showMap)?_c('w-map',{style:({
        position: 'fixed',
        right: '0',
        bottom: '0',
        width: '100%',
        height: '100%'
      }),attrs:{"box":_vm.box},on:{"closeMap":function($event){_vm.showMap = false},"setLocation":_vm.setLocation}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }