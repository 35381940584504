var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-10 col-md-6 col-xl-4 bg-white rounded p-4 p-md-5 shadow d-flex flex-column align-items-center"},[_c('label',{class:{
      'mb-3 w-100': true,
      error: _vm.$v.name.$error,
      green: _vm.$v.name.$dirty && !_vm.$v.name.$error
    }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"w-100 brown-wGreyMedium font-rem-0-8 rounded border",attrs:{"placeholder":"Nombre"},domProps:{"value":(_vm.name)},on:{"change":function($event){return _vm.$v.name.$touch()},"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})]),_c('transition-expanded',[(_vm.$v.name.$dirty && _vm.$v.name.$error)?_c('div',{staticClass:"align-self-start"},[_c('p',{staticClass:"font-rem-0-75 error"},[_vm._v(" Debe ser un nombre de almenos 4 caracteres ")])]):_vm._e()]),_c('label',{class:{
      'mb-3 w-100': true,
      error: _vm.$v.phone.$error,
      green: _vm.$v.phone.$dirty && !_vm.$v.phone.$error
    }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"w-100 brown-wGreyMedium font-rem-0-8 rounded border",attrs:{"type":"number","disabled":_vm.$route.query.new || (_vm.phone && _vm.phone.length) > 6 ? false : true,"placeholder":"Celular"},domProps:{"value":(_vm.phone)},on:{"change":function($event){return _vm.$v.phone.$touch()},"input":function($event){if($event.target.composing)return;_vm.phone=$event.target.value}}})]),_c('transition-expanded',[(_vm.$v.phone.$dirty && _vm.$v.phone.$error)?_c('div',{staticClass:"align-self-start"},[_c('p',{staticClass:"font-rem-0-75 error"},[_vm._v(" Debe ser un numero valido Colombiano, sin codigo de pais ")])]):_vm._e()]),_c('label',{class:{
      'mb-3 w-100': true,
      error: _vm.$v.email.$error,
      green: _vm.$v.email.$dirty && !_vm.$v.email.$error
    }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"w-100 brown-wGreyMedium font-rem-0-8 rounded border",attrs:{"disabled":_vm.$route.query.new || (_vm.email && _vm.email.length) > 6 ? false : true,"placeholder":"Correo electrónico"},domProps:{"value":(_vm.email)},on:{"change":function($event){return _vm.$v.email.$touch()},"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('transition-expanded',[(_vm.$v.email.$dirty && _vm.$v.email.$error)?_c('div',{staticClass:"align-self-start"},[_c('p',{staticClass:"font-rem-0-75 error"},[_vm._v("Debe ser un email valido")])]):_vm._e()]),_c('label',{class:{
      'mb-3 w-100': true,
      error: _vm.$v.password.$error,
      green: _vm.$v.password.$dirty && !_vm.$v.password.$error
    }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"w-100 brown-wGreyMedium font-rem-0-8 rounded border",attrs:{"type":"password","placeholder":"Contraseña"},domProps:{"value":(_vm.password)},on:{"change":function($event){return _vm.$v.password.$touch()},"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),_c('transition-expanded',[(_vm.$v.password.$dirty && _vm.$v.password.$error)?_c('div',{staticClass:"align-self-start"},[_c('p',{staticClass:"font-rem-0-75 error"},[_vm._v("debe tener almenos 6 caracteres")])]):_vm._e()]),_c('label',{staticClass:"mb-3 w-100"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company),expression:"company"}],staticClass:"w-100 brown-wGreyMedium font-rem-0-8 rounded border",attrs:{"placeholder":"Institución / empresa"},domProps:{"value":(_vm.company)},on:{"change":function($event){return _vm.$v.$touch()},"input":function($event){if($event.target.composing)return;_vm.company=$event.target.value}}})]),_c('button',{staticClass:"py-2 px-3 mt-3 w-75 h-50 rounded bg-dark-green shadow-sm pointer d-flex justify-content-center text-white w-fit-content",attrs:{"disabled":_vm.$v.$invalid},on:{"click":function($event){return _vm.saveClick()}}},[(!_vm.user.id)?_c('span',{staticClass:"align-self-center"},[_vm._v(" registrarse")]):_c('span',{staticClass:"align-self-center"},[_vm._v(" Guardar cambios")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }