var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-absolute d-flex flex-column"},[_c('head-bar'),_c('div',{class:`${
      _vm.$route.query.single || _vm.$route.query.listing ? '' : 'head-bar-visible'
    } bg-gray-light w-100 px-1 visible-nav-bar`,style:({
      'overflow-x': 'hidden',
      'min-height': '100vh',
      width: '100vw !important'
    })},[(_vm.view == 'profile')?_c('profile-view'):_vm._e(),(_vm.view == 'boxes')?_c('boxes-view'):_vm._e(),(_vm.view == 'calendar')?_c('calendar-view'):_vm._e(),(_vm.view == 'notifications')?_c('notifications-view'):_vm._e()],1),_c('nav-bar')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }