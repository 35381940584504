var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`${
    (_vm.$route.query.single &&
      (_vm.$route.params.view == 'boxes' || !_vm.$route.params.view)) ||
    _vm.$route.query.listing
      ? 'nav-bar-ab'
      : 'nav-bar'
  } z-100 border-bottom d-flex justify-content-around align-items-center w-100 bg-white`},[_c('div',{class:_vm.colorClass('profile'),on:{"click":function($event){return _vm.navigateTo('profile')}}},[_c('inline-svg',{attrs:{"src":"/perfil.svg","width":"30px","height":"30px","fill":"white","aria-label":"Perfil"}})],1),_c('div',{staticClass:"vl"}),_c('div',{class:_vm.colorClass('boxes'),on:{"click":function($event){return _vm.navigateTo('boxes')}}},[_c('inline-svg',{attrs:{"src":"/monitoreo.svg","width":"30px","height":"30px","fill":"white","aria-label":"Loading..."}})],1),_c('div',{staticClass:"vl"}),_c('div',{class:_vm.colorClass('calendar'),on:{"click":function($event){return _vm.navigateTo('calendar')}}},[_c('inline-svg',{attrs:{"src":"/calendario.svg","width":"30px","height":"30px","fill":"white","aria-label":"Loading..."}})],1),_c('div',{staticClass:"vl"}),_c('div',{class:_vm.colorClass('notifications'),on:{"click":function($event){return _vm.navigateTo('notifications')}}},[_c('inline-svg',{attrs:{"src":"/notificaciones.svg","width":"30px","height":"30px","fill":"white","aria-label":"Loading..."}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }